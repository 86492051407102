import React from "react";
import "./Footer.scss";
import Logo from "../../assets/logos/Logo";
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <div className="footer-container">
            <div className="logo-section">
                <Logo fillColour="#ffffff" width={264} height={82}/>
                <div>
                    <h4>Software Development & Consultation.</h4>
                    <div>© 2024 EDBWN Software</div>
                </div>
            </div>
            <div className="link-section">
                <div className="explore">
                    <h3>Explore</h3>
                    <div>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/dev">Development</Link>
                            </li>
                            <li>
                                <Link to="/consultation">Consultation</Link>
                            </li>
                            <li>
                                <Link to="/about-us">About Us</Link>
                            </li>
                            <li>
                                <Link to="/blog">Blog</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="website">
                    <h3>Website</h3>
                    <div>
                        <ul>
                            <li>
                                <Link to="/contact">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="/terms">Terms</Link>
                            </li>
                            <li>
                                <Link to="/privacy">Privacy & Cookies</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="company">
                    <h3>Company</h3>
                    <div>
                        <ul>
                            <li className="info">
                                <a href="mailto:contact@edbwn.com">contact@edbwn.com</a>
                            </li>
                            <li className="info">
                                <a href="tel:+447841727248">+44 7841727248</a>
                            </li>
                            <li className="info">
                                <a target="_blank"
                                   href="http://maps.google.com/?q=33 St Andrewgate, York, YO1 7BR, United Kingdom">
                                    <div>33 St Andrewgate</div>
                                    <div>York</div>
                                    <div>YO1 7BR</div>
                                    <div>United Kingdom</div>
                                </a>
                            </li>
                            <li className="info">
                                <a target="_blank"
                                   href="https://find-and-update.company-information.service.gov.uk/company/14691935">
                                    Company No: 14691935
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}