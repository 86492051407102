import {useEffect, useState} from "react";
import axios from "axios";

export default function useBlog(id) {
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetch = async () => {
            const bs = await axios.get(`https://edbwn-website-funcs-prod.azurewebsites.net/api/blogs/${id}`);

            setBlog(bs.data);
            setLoading(false);
        }

        fetch();
    }, []);

    return [blog, loading];
}