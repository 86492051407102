import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/home/Home";
import Development from "./pages/services/Development";
import AboutUs from "./pages/about-us/AboutUs";
import Blog from "./pages/blog/Blog";
import Contact from "./pages/contact/Contact";
import Terms from "./pages/terms/Terms";
import Privacy from "./pages/privacy/Privacy";
import NotFound from "./pages/not-found/NotFound";
import ScrollTop from "./components/scroll-top/ScrollTop";
import Animations from "./components/scroll-top/Animations";
import BlogDetails from "./pages/blog-details/BlogDetails";
import Consultation from "./pages/consultation/Consultation";

function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route element={<Layout/>}>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/dev" element={<Development/>}/>
                        <Route path="/consultation" element={<Consultation/>}/>
                        <Route path="/about-us" element={<AboutUs/>}/>
                        <Route path="/blog" element={<Blog/>}/>
                        <Route path="/blog/:id" element={<BlogDetails/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/terms" element={<Terms/>}/>
                        <Route path="/privacy" element={<Privacy/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Route>
                </Routes>
                <ScrollTop/>
                <Animations/>
            </BrowserRouter>
        </>
    );
}

export default App;
