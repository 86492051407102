import React from "react";
import "./Blogs.scss";
import useBlogs from "../../../../hooks/useBlogs";
import {Link} from "react-router-dom";
import Animations from "../../../../components/scroll-top/Animations";

export default function Blogs() {
    const [blogs, loading] = useBlogs();

    if (loading) {
        return (
            <div className="blog-container">
                <div className="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }
    
    return (
        <div className="blogs-container animate">
            <h2 className="fadeInUp--0">Latest News</h2>
            <div className="card-container">
                <div className="card-area">
                    {blogs.map(blog => {
                        return (
                            <Link key={blog.id} to={`blog/${blog.id}`}>
                                <div className="card blog-card has-action fadeInUp--2">
                                    <div className="image">
                                        <img src={blog.imageSmall}/>
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            {blog.title}
                                        </div>
                                        <div className="sub-title">
                                            {new Date(blog.createdAt).toLocaleDateString("en-GB")} &bull; {blog.readTime}
                                        </div>
                                        <div className="details">
                                            {blog.description}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
            <Animations></Animations>
        </div>
    );
}