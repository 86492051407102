import React, {useEffect, useState} from "react";
import "./Contact.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faChevronRight, faPhone} from "@fortawesome/free-solid-svg-icons";
import Input from "../../components/input/Input";
import useFormControl from "../../hooks/useFormControl";
import Validators from "../../utilities/validators";
import axios from "axios";
import Animations from "../../components/scroll-top/Animations";
import {Helmet} from "react-helmet";

export default function Contact() {
    const [firstName, onFirstNameChange] = useFormControl("First Name", "", [Validators.required]);
    const [lastName, onLastNameChange] = useFormControl("Last Name", "", [Validators.required]);
    const [email, onEmailChange] = useFormControl("Email", "", [Validators.required, Validators.email]);
    const [phone, onPhoneChange] = useFormControl("Phone Number", "", [Validators.required, Validators.phone]);
    const [description, onDescriptionChange] = useFormControl("How can we help?", "");

    const [showError, setShowError] = useState(false);

    const [loading, setLoading] = useState(false);

    const submitted = new Date(localStorage.getItem("submitted"));
    submitted.setDate(submitted.getDate() + 1);

    const hasErrors = () => {
        const error1 = onFirstNameChange(firstName.value);
        const error2 = onLastNameChange(lastName.value);
        const error3 = onEmailChange(email.value);
        const error4 = onPhoneChange(phone.value);
        const error5 = onDescriptionChange(description.value);

        return !!(error1 || error2 || error3 || error4 || error5);
    }

    const handleSubmit = async () => {
        if (hasErrors()) {
            return;
        }

        setLoading(true);

        const request = {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            phone: phone.value,
            description: description.value
        }

        document.getElementById("book-a-callback").click();
        const result = await axios.post(`https://edbwn-website-funcs-prod.azurewebsites.net/api/callback`, request)
            .catch(err => {
                setShowError(true);
            });
        
        setLoading(false);
        localStorage.setItem("submitted", new Date().toISOString());
    }

    let formTemplate = (
        <div className="contact-container animate">
            <Helmet>
                <title>Get in Touch, we are here to help | EDBWN Software</title>
                <meta name="description"
                      content="Our aim is to aid individuals and organisations in realizing their goals."/>
                <meta name="keywords"
                      content="contact, contact page, get in touch, software, development, support, bespoke, project, it, edbwn, edbwn software"/>
                <meta property="og:title" content="Get in Touch, we are here to help | EDBWN Software"/>
                <meta property="og:description"
                      content="Our aim is to aid individuals and organisations in realizing their goals."/>
                <meta property="og:image" content="https://edbwn.com/white.png"/>
                <meta property="og:url" content="https://edbwn.com/contact"/>
            </Helmet>
            <div className="header">
                <h1 className="fadeInUp--0">Book a Call</h1>
                <h5 className="fadeInUp--1">We going to start with a quick introduction call, where we will answer any burning questions.</h5>
            </div>
            <div className="contact-form">
                <div className="form-element first-name fadeInUp--2">
                    <Input form={firstName} type="text" setValue={onFirstNameChange}/>
                </div>
                <div className="form-element last-name fadeInUp--3">
                    <Input form={lastName} type="text" setValue={onLastNameChange}/>
                </div>
                <div className="form-element email fadeInUp--4">
                    <Input form={email} type="text" setValue={onEmailChange}/>
                </div>
                <div className="form-element phone fadeInUp--5">
                    <Input form={phone} type="text" setValue={onPhoneChange}/>
                </div>
                <div className="form-element description fadeInUp--6">
                    <Input form={description} type="textarea" setValue={onDescriptionChange}/>
                </div>
                <div className="form-element submit fadeInUp--7">
                    <label
                        className={showError ? 'error show' : 'error'}>We apologize for the inconvenience, but it seems there was an error while submitting the form. If the issue persists, please do not hesitate to reach out to us at +44 784 1727 248. We appreciate your patience and assistance.</label>

                    {
                        loading
                            ? (
                                <div className="spinner">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            )
                            : (
                                <>
                                    <button onClick={handleSubmit} className="primary w100 book-call">
                                        <span>Submit</span>
                                        <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                                        <div className="arrow"><FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon></div>
                                    </button>
                                    <button id="book-a-callback" style={{'display': 'none'}}>Submit</button>
                                </>
                            )
                    }
                </div>
            </div>
            <div className="card initial fadeInUp--6">
                <div className="content">
                    <div className="title">Initial Call</div>
                    <div className="sub-title">Introductory call to grasp your needs at a high level.</div>
                </div>
            </div>
            <div className="statement mx--5 fadeInUp--4">
                Our aim is to aid individuals and organisations in realizing their goals.
            </div>
            <div className="card testimony fadeInUp--3">
                <div className="image">
                    <img alt="James Tognola - WJFG Limited Testimonial" loading="lazy" src="/James.jpg"/>
                </div>
                <div className="content">
                    <div className="title">
                        James Tognola
                    </div>
                    <div className="sub-title">
                        Head of Ecommerce and Development at WJFG Limited
                    </div>
                    <div className="details">
                        Ed and EDBWN exceeded our expectations, delivering the smoothest project experience we've ever had. We thoroughly enjoyed working with Ed and eagerly anticipate collaborating with EDBWN Software again.
                    </div>
                </div>
            </div>
            <Animations></Animations>
        </div>
    );
    if (submitted > new Date()) {
        formTemplate = (
            <div className="contacted animate">
                <img alt="Callback has been booked" src="/high-five.svg" className="fadeInUp--0"/>
                <h3 className="mx--25 fadeInUp--1">Thank you for reaching out! We'll be in touch soon.</h3>
                <Animations></Animations>
            </div>
        );
    }

    return (
        formTemplate
    );
}