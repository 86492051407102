import {useEffect, useState} from "react";
import axios from "axios";

export default function useBlogs() {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetch = async () => {
            const bs = await axios.get("https://edbwn-website-funcs-prod.azurewebsites.net/api/blogs");
            
            setBlogs(bs.data);
            setLoading(false);
        }

        fetch();
    }, []);

    return [blogs, loading];
}